
































































import { VeeValidateObserverRef } from '@/types/libraries/VeeValidateObserverRef';
import { AuthLoginRequest, AuthLoginResponse } from '@/types/api/AuthLogin';
import { ApiResponse } from '@/types/api/Response';
import { AxiosError } from 'node_modules/axios';
import Vue from 'vue';
import { User } from '@/store/auth';
import { NavMenu } from '@/types/NavMenu';

export default Vue.extend({
  'name': 'ComponentLogin',
  data () {
    return {
      'form': {
        'email': '',
        'password': ''
      } as AuthLoginRequest
    };
  },
  'computed': {
    navMenus (): Array<NavMenu> {
      return this.$store.getters.navMenus;
    }
  },
  'methods': {
    onSubmit () {
      this.axios.put('login', this.form)
        .then(({ data }) => {
          const response = data as AuthLoginResponse;

          this.$store.commit('setAuthenticationStatus', {
            'token': response.result.token,
            'username': '',
            'userId': response.result.id,
            'role': response.result.role
          } as User);

          this.$store.commit('resetAppState');

          if (this.navMenus.length > 0) {
            this.$router.push({
              'name': this.navMenus[0].routeName
            });
          }
        }).catch((err: AxiosError) => {
          if (err.response && err.response.status === 401) {
            const data = err.response.data as ApiResponse;
            (this.$refs.form as VeeValidateObserverRef).setErrors({
              'email': [data.message],
              'password': [data.message]
            });
          }
        });
    }
  }
});
